import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import moment from 'moment'
import '../sass/main.scss'
import 'react-dates/initialize'
import '../sass/components/date-picker.scss'
// => from 'react-dates/lib/css/_datepicker.css'
import { SingleDatePicker } from 'react-dates'
// import { DateRangePicker } from 'react-dates'
import FormModal from '../components/FormModal'

import { sendEmail } from '../services/api'
import Layout from '../components/layout'
import Head from '../components/head'

import contactStyles from '../sass/pages/contact.module.scss'
import commonStyles from '../sass/components/common.module.scss'
import formStyles from '../sass/components/form.module.scss'
import bodyStyles from '../sass/components/body.module.scss'
import typographyStyles from '../sass/abstract/typography.module.scss'
import buttonStyles from '../sass/components/button.module.scss'
import talkIconSvg from '../assets/icons/vcd-talk-color.svg'
import swimmingPoolIconSvg from '../assets/icons/vcd-swimming-pool-color.svg'
import mountainIconSvg from '../assets/icons/vcd-mountain-color.svg'
import houseIconSvg from '../assets/icons/vcd-house-color.svg'

import {
  swimmingPoolIconAlt,
  mountainIconAlt,
  talkIconAlt,
  houseIconAlt,
} from '../constants/iconAlt'

const cS = contactStyles
const cmS = commonStyles
const fS = formStyles
const bS = bodyStyles
const tS = typographyStyles
const btS = buttonStyles

////////////////////////////////////////////////////////

const ContactPage = () => {
  // Contact form variables
  const [email, setEmail] = useState(false)
  const [name, setName] = useState(false)
  const [mobile, setMobile] = useState(false)
  const [location, setLocation] = useState(false)
  const [date, setDate] = useState(moment().add(1, 'Days'))
  const [duration, setduration] = useState(false)
  const [calendarFocused, setFocus] = useState(false)

  // Visibility and sending msg variables
  const [childVisible, toggleChildVisible] = useState(true) //used to display SingleDatePicker > refactory : change names
  const [messageEnvoi, setMessageEnvoi] = useState(false)
  const [messageFillPlease, setMessageFillPlease] = useState(false)
  const [modalChildVisible, setModalChildVisible] = useState(false)

  const toggleVisible = () => toggleChildVisible(!childVisible)
  const modalVisible = () => setModalChildVisible(true)
  const modalHidden = () => setModalChildVisible(false)

  const handleClickForm = () => {
    console.log([
      email,
      name,
      mobile,
      location,
      moment(date).format('l'),
      duration,
    ])

    if (email && location && moment(date) > moment() && duration) {
      const locationMessage = `${location} à partir de ${moment(date).format(
        'dddd, D MMM YYYY'
      )}, pour ${duration} semaines.`

      const messageClient = `Bonjour je souhaiterais obtenir des information au sujet de la location ${location},  à partir de ${moment(
        date
      ).format('dddd, D MMM YYYY')}, pour ${duration} semaines.`

      // First step after having checked the content: display "Message sending..."
      console.log('Display "Sending Message"')
      setMessageEnvoi('Envoi de la demande...')
      setMessageFillPlease('')
      modalVisible()

      // Second step : send the email via an api call
      setTimeout(() => {
        console.log([messageClient, email, mobile, locationMessage])

        // console.log('heyyyyyy')

        sendEmail(messageClient, email, mobile, locationMessage).then(() => {
          console.log('Display "Sent"')
          setMessageEnvoi('')
          setMessageFillPlease('')
          modalVisible()

          setTimeout(() => {
            // Just wait for the user to read
            console.log('Close modal now')
            modalHidden()
          }, 1000)
        })

        
      }, 1500)
    } else if (!location) {
      setMessageEnvoi('')
      setMessageFillPlease(`Veuillez choisir une location svp.`)
      modalVisible()

      setTimeout(() => {
        console.log('Hide modal now')
        setMessageFillPlease('')
        modalHidden()
        // setName(name)
        // setEmail(email)
        // setDate(date)
      }, 2000)
    } else if (!email) {
      setMessageEnvoi('')
      setMessageFillPlease(`Un email nous sera nécessaire pour vous joindre!`)
      modalVisible()

      setTimeout(() => {
        console.log('Hide modal now')
        setMessageFillPlease('')
        modalHidden()
      }, 2000)
    } else if (moment(date) < moment()) {
      setMessageEnvoi('')
      setMessageFillPlease(
        `Veuillez rentrer une date de début de séjour ultérieure à aujourd'hui svp.`
      )
      modalVisible()

      setTimeout(() => {
        console.log('Hide modal now')
        setMessageFillPlease('')
        modalHidden()
      }, 2000)
    } else if (!duration) {
      setMessageEnvoi('')
      setMessageFillPlease(`Veuillez rentrer une durée de séjour svp.`)
      modalVisible()

      setTimeout(() => {
        console.log('Hide modal now')
        setMessageFillPlease('')
        modalHidden()
      }, 2000)
    }
  }

  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        locations {
          edges {
            node {
              title
              slug
              locationAcf {
                orderOfAppearance
              }
            }
          }
        }
      }
    }
  `)

  // dateStart1Aug
  // dateStart1Jul
  // dateStart1Jun
  // dateStart1Sep
  // dateStart2Aug
  // dateStart2Jul
  // dateStart2Jun
  // dateStart2Sep
  // dateStart3Aug
  // dateStart3Jul
  // dateStart3Jun
  // dateStart3Sep
  // dateStart4Aug
  // dateStart4Jul
  // dateStart4Jun
  // dateStart4Sep

  let orderOfAppArray = []

  data.wpgraphql.locations.edges.forEach((edge, index) => {
    orderOfAppArray.push(edge.node.locationAcf.orderOfAppearance)
  })

  // Sort the "data.wpgraphql.locations.edges" array based on "orderOfApp" elements
  // build an array containing "--edges" and corresponding order of appearance
  let arrayIndexed = data.wpgraphql.locations.edges.map(
    (inputElement, index) => {
      const outputElement = {
        inputElement,
        index: orderOfAppArray[index],
      }
      return outputElement
    }
  )
  // sort upon order of appearance
  arrayIndexed
    .sort((a, b) => a.index - b.index)
    .map((arrayIndexed, index, array) => arrayIndexed.name)

  // console.log(arrayIndexed)
  // test with dates from graphql !

  useEffect(() => {
    if (date) {
      // console.log(date)
      toggleVisible()
    }
    return () => {
      console.log('"submitted" useEffect : Cleaning up, loading events.')
    }
  }, [date])

  return (
    <Layout>
      <Head title={'Contact'} />

      <div className={bS.container}>
        <h1 className={cmS.title}> Contact </h1>
        <div className={cmS.iconWrapperWrapper}>
          <div className={cmS.iconWrapper}>
            <img
              className={cmS.box1Icon}
              src={swimmingPoolIconSvg}
              alt={swimmingPoolIconAlt}
            />
            <img
              className={cmS.box2Icon}
              src={houseIconSvg}
              alt={houseIconAlt}
            />
            <img className={cmS.box2Icon} src={talkIconSvg} alt={talkIconAlt} />
            <img
              className={cmS.box2Icon}
              src={mountainIconSvg}
              alt={mountainIconAlt}
            />
          </div>
        </div>

        {/*     Section Formulaire de Contact       */}
        <div className={cmS.mainComponentWrapper}>
          <h1 className={cmS.subtitle}>Formulaire de contact</h1>

          <div className={cS.paragraphBox2}>
            <div className={cmS.paragraph}>
              <p>
                Le formulaire ci-dessous, vous permet d'envoyer directement
                votre demande d'information, avec précision, et par email !
              </p>
            </div>
          </div>

          <div className={cS.paragraphWrapper}>
            <div className={cS.bookingWrapper}>
              <div className={cS.bookingWrapperForm}>
                <form action="#" className="form">
                  <div className={cS.bookingHeadingWrapper}>
                    <h2 className={tS.headingSecondary}>
                      Réservez vos vacances!
                    </h2>
                  </div>

                  <div className={fS.formGroup}>
                    <input
                      onChange={ev => setName(ev.target.value)}
                      type="text"
                      className={fS.formInput}
                      placeholder="Prénom"
                      id="name"
                      required
                    />
                    <label for="name" className={fS.formLabel}>
                      Prénom
                    </label>
                  </div>

                  <div className={fS.formGroup}>
                    <input
                      onChange={ev => setEmail(ev.target.value)}
                      type="email"
                      className={fS.formInput}
                      placeholder="Email"
                      id="email"
                      required
                    />
                    <label for="email" className={fS.formLabel}>
                      Email
                    </label>
                  </div>

                  <div className={fS.formGroup}>
                    <input
                      onChange={ev => setMobile(ev.target.value)}
                      type="text"
                      className={fS.formInput}
                      placeholder="Mobile"
                      id="mobile"
                      required
                    />
                    <label for="mobile" className={fS.formLabel}>
                      Mobile
                    </label>
                  </div>

                  <div className={fS.formGroup}>
                    <select
                      name=""
                      id=""
                      onChange={ev => setLocation(ev.target.value)}
                      className={fS.formInputLocation}
                    >
                      <option selected value="">
                        Choisissez une location
                      </option>
                      {arrayIndexed.map((edge, index) => (
                        <option
                          key={edge.inputElement.node.title}
                          value={edge.inputElement.node.title}
                        >
                          {edge.inputElement.node.title}
                        </option>
                      ))}
                    </select>
                    <label for="location" className={fS.formLabel}>
                      Ma location
                    </label>
                  </div>

                  <div className={fS.formGroup}>
                    <div className={fS.doubleFormInputWrapperWrapper}>
                      <div className={fS.doubleFormInputWrapper}>
                        {childVisible ? (
                          <div>
                            <SingleDatePicker
                              date={date}
                              onDateChange={setDate}
                              focused={calendarFocused}
                              onFocusChange={calendarFocused =>
                                setFocus(calendarFocused)
                              }
                              numberOfMonths={1}
                              isOutsideRange={() => false}
                            />
                          </div>
                        ) : (
                          <div className={fS.doubleFormInput}>
                            <p>{moment(date).format('DD/MM/YYYY')}</p>
                          </div>
                        )}

                        {/*
                  <select
                  name=""
                  id=""
                  onChange={ev => setEmail(ev.target.value)}
                  className={fS.doubleFormInput}
                > if location selected compute dates vector then map select : loop on edge.inputElement.node.locationAcf. >here<
                  filtering (filter) on availability {arrayIndexed.map((edge, index) => (
                    <option
                      key={edge.inputElement.node.title}
                      value={edge.inputElement.node.title}
                    >
                      {edge.inputElement.node.title}
                    </option>
                  ))}
                </select> */}

                        <label
                          for="date début"
                          className={fS.formLabelDarkDynamic}
                          onClick={toggleVisible}
                        >
                          Change début
                        </label>
                      </div>
                      <div class="px-4 py-4"></div>
                      <div className={fS.doubleFormInputWrapper}>
                        <select
                          name=""
                          id=""
                          onChange={ev => setduration(ev.target.value)}
                          className={fS.doubleFormInput2}
                        >
                          <option selected value="">
                            Durée
                          </option>
                          <option value={1}>3 à 6 jours</option>
                          <option value={2}>1 sem</option>
                          <option value={3}>2 sem</option>
                          <option value={4}>3 sem</option>
                          <option value={5}>4 sem</option>
                        </select>
                        <label for="durée" className={fS.formLabelDark}>
                          Durée
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className={fS.formGroup}>
                    <div className={cS.buttonsWrapper}>
                      <div className={cS.individualButtonWrapper}>
                        <div
                          onClick={() => {
                            handleClickForm()
                          }}
                        >
                          {/*  <div className="flex flex-1 h-1"></div>*/}
                          <button className={btS.buttonFormSend}>
                            Envoyer ma demande
                          </button>
                          {/*   <div className="flex flex-1 h-1"></div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div onClick={modalHidden}>
                  {modalChildVisible ? (
                    <FormModal
                      messageEnvoi={messageEnvoi}
                      messageFillPlease={messageFillPlease}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*     Section Autres moyens de contact       */}

        <div className={cmS.mainComponentWrapper}>
          <h1 className={cmS.subtitle}>Varcamdobb est à la page !</h1>
          <div className={cmS.paragraphWrapper}>
            <div className={cS.paragraphBox}>
              <div className={cmS.paragraph}>
                <p>
                  Pour nous joindre, vous avez le choix parmi tous les moyens
                  numériques modernes... même le courrier papier si vous le
                  souhaitez!
                  <br />
                  <br />
                  Mais si La Poste 📯 est aussi en mode vacances, il vous faudra
                  patienter jusqu'à l'été prochain pour le coucher de soleil à
                  Salernes accompagné d'un bon rosé.
                </p>
              </div>
            </div>
            <div className={cS.paragraphBox}>
              <div className={cmS.paragraph}>
                <div className="w-full">
                  Vous pouvez donc utiliser notre chatbox, le formulaire de
                  contact, lui envoyer un email sur
                  <a
                    className={cS.email}
                    href="mailto:varcamdobb@hotmail.com?subject = Locations à Salernes. &body = Suite à la consultation de votre site, je souhaite reecvoir des informations au sujet des locations (écrire ici le nom de la location que vous souhaiteriez réserver) ... à Salernes."
                  >
                    varcamdobb@hotmail.com
                  </a>
                  . Gérard est toujours joignable sur son téléphone :
                  <a className={cS.email} href="tel:+33682408878">
                    appel
                  </a>
                  ,
                  <a
                    className={cS.email}
                    href="sms://+33682408878?body=Bonjour%20je%20souhaite%20être%20rappelé%20concernant%20une%20location%20à%20Salernes%20cet%20été.%20Merci%20Envoyé%20via%20Varcamdobb.com."
                  >
                    texto/sms
                  </a>
                  , <br />
                  <a
                    className={cS.email}
                    href="whatsapp://send?text=Bonjour Gérard, je viens de visiter votre site et suis intéressé par une location pour les prochaines vacances d'été. A très bientôt, &phone=+33682408878"
                  >
                    Whatsapp
                  </a>
                  ou
                  <a
                    className={cS.email}
                    href="https://www.facebook.com/villasvarcamdobb"
                  >
                    Facebook
                  </a>
                  .<br />
                  Vous pouvez essayer les 4 ensemble mais il n'a que deux
                  oreilles 😉.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
