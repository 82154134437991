import React from 'react'

// import cl from "../utils/cl"
import formModalStyles from '../sass/components/formmodal.module.scss'
const fmS = formModalStyles

  const FormModal = ({ messageEnvoi, messageFillPlease }) => {
  return (
    <div className={fmS.formModalContainer}>
      <div className={fmS.formModalTextBox}>
      { messageFillPlease ? 
        (  <span className={fmS.formModalTitle}>
          Désolé mais... </span> )
      : messageEnvoi ? 
          ( <span className={fmS.formModalTitle}> {messageEnvoi} <br/> ⏳</span> )
            : ( 
              <span className={fmS.formModalTitle}> Merci <br/> 😃</span> )
          }

         
        { !messageEnvoi ? 
          ( <span className={fmS.formModalText}> {
            messageFillPlease ? 
            messageFillPlease : 
            <span>  Votre message a bien été transmis à Gérard 📯, et une copie a été envoyée sur votre boite email. </span>
          } </span> )
            : ( <span className={fmS.formModalText}> Notre batterie de pigeons voyageurs volent à toute vitesse pour transmettre votre message... 🦅🦅🦅🐥🦉</span> )
          }
      </div>
    </div>
  )
}

export default FormModal
